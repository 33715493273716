
import { computed, defineComponent, nextTick, onMounted, onUnmounted, reactive, ref, compile } from 'vue'
import httpRequest from '@/service'
import { useRoute, useRouter } from 'vue-router'
import BreadCrumb from '@/components/breadCrumb/index.vue'
import SearchInput from '@/views/DetailPage/cpns/searchInput.vue'
import RightPane from '@/views/DetailPage/cpns/rightPane.vue'
import Rules from '@/views/DetailPage/components/MainView/Rules.vue'
import FoldPanel from '@/views/DetailPage/components/MainView/FoldPanel.vue'
import TagPanel from '@/views/DetailPage/components/MainView/TagPanel.vue'
import { ElMessage } from 'element-plus/lib/components'
import { IComObj } from '@/global/types'
import { isCollect } from '@/utils/collect'
import { debounce } from 'throttle-debounce'
import { jumpNewPage } from '@/views/DetailPage/utils' // 详情页链接跳转新页面
import { theme } from '@/theme/config/theme.config'
// import '@vueup/vue-quill/dist/vue-quill.snow.css'
import { Position } from '@element-plus/icons-vue'
import PopoverMenu from '@/components/PopoverMenu/index.vue'
import usePopoverMenu from '@/utils/usePopoverMenu'
import { generateTagStyle } from '@/utils/css'
export default defineComponent({
  name: 'DetailPage',
  components: {
    Position,
    Rules,
    SearchInput,
    BreadCrumb,
    RightPane,
    FoldPanel,
    TagPanel,
    PopoverMenu
  },
  setup() {
    const { popShow, popLeft, popTop, showPop,handleCopy,handleSearch } = usePopoverMenu((value)=>{
      doParamSearch('fulltext',value,value)
    })
    const matchLink = (list) => {
      for (const key in detailData.value) {
        if([
          // 'cpwsAlCpyz',
        'cpwsAlGlsy',
        // 'cpwsAlJbaq','cpwsAlCpjg','cpwsAlCply'
      ].includes(key)){
          list.forEach(item => {
            detailData.value[key] = detailData.value[key].replaceAll(item.name,'<span class="relate-link" data-id="'+item.id+'">'+item.name+'</span>')
          })
        }
      }
    }
    const dynamicContent = computed(() => (content: string) => {
      let dcontent = content.replaceAll(/(<span class="relate-link" data-id="([0-9]+?)">.*?<\/span>)/g, `
      <el-popover
        popper-class="hover-pop"
        :width="800"
        :show-arrow="false"
        placement="top"
        trigger="hover"
        @show="lawSummary.loadData('$2')">
        <template #reference>
          <span v-html='\`$1\`'></span>
        </template>
        <div
          class="hover-law-summary"
          v-loading="lawSummary.loading()"
          element-loading-background="rgba(0, 0, 0, 0)">
          <div class="law-summary-title" v-if="!lawSummary.loading()">
        <h3 @click="lawSummary.jump('$2')">
          {{ lawSummary.data().lawName || '法律标题' }}
        </h3>
          </div>
          <hr v-if="!lawSummary.loading()" />
          <div class="law-summary-properties" v-if="!lawSummary.loading()">
        <el-row>
          <el-col :span="14">
        <span class="law-summary-properties-label">发布机关</span>
        <span class="law-summary-properties-value">{{ lawSummary.data().publishOrgName }}</span>
          </el-col>
          <el-col :span="10">
        <span class="law-summary-properties-label">时效性</span>
        <span class="law-summary-properties-value">{{ lawSummary.data().timelinessName }}</span>
          </el-col>
          <el-col :span="14">
        <span class="law-summary-properties-label">发文字号</span>
        <span class="law-summary-properties-value">{{ lawSummary.data().lawNumber }}</span>
          </el-col>
          <el-col :span="10">
        <span class="law-summary-properties-label">效力级别</span>
        <span class="law-summary-properties-value">{{ lawSummary.data().effectLevelName }}</span>
          </el-col>
          <el-col :span="14">
        <span class="law-summary-properties-label">发布日期</span>
        <span class="law-summary-properties-value">{{ lawSummary.data().publishDate }}</span>
          </el-col>
          <el-col :span="10">
        <span class="law-summary-properties-label">实施日期</span>
        <span class="law-summary-properties-value">{{ lawSummary.data().implDate }}</span>
          </el-col>
        </el-row>
          </div>
        </div>
      </el-popover>
      `)
      return compile(dcontent)
    })
    // // 超链接
    const cacheObj: { [index: string]: any } = {}
    const lawSummary = reactive({
      currentId: '',
      data() {
        return this.cache['law_' + this.currentId] || {
          lawVersionId: undefined,
          lawName: undefined,
          publishOrgName: undefined,
          timelinessName: undefined,
          lawNumber: undefined,
          effectLevelName: undefined,
          publishDate: undefined,
          implDate: undefined
        }
      },
      cache: cacheObj,
      loading() {
        return !this.cache['law_' + this.currentId]
      },
      loadData(lawVersionId: string) {
        if (!lawVersionId) {
          return
        }
        this.currentId = lawVersionId
        if (this.cache['law_' + lawVersionId]) {
          return
        }
        httpRequest
          .get({
            url: 'lawSummary',
            params: {
              lawVersionId: lawVersionId
            }
          })
          .then((res: any) => {
            this.cache['law_' + lawVersionId] = res.data
          })
          .catch((err) => {
            ElMessage({
              message: '法律摘要加载失败',
              type: 'error'
            })
          })
      },
      jump(lawVersionId: string) {
        const { href } = router.resolve({
          name: 'DetailPage',
          query: {
            id: lawVersionId
          }
        })
        window.open(href, '_blank')
      }
    })
    // 获取元素的位置信息
    const getElementPosition = (elementRef: any) => {
      if (elementRef) {
        const rect = elementRef.getBoundingClientRect()
        const windowHeight = window.innerHeight || document.documentElement.clientHeight
        const windowWidth = window.innerWidth || document.documentElement.clientWidth

        // 判断元素是否在屏幕内
        return (
          rect.top >= 0 &&
          rect.left >= 0 &&
          rect.bottom <= windowHeight &&
          rect.right <= windowWidth
        )
      }
      return false
    }

    const tabActive = ref('')

    // 监听滚动事件
    const handleScroll = () => {
      const elements = document.querySelectorAll('.cardTitle')
      for (let i = 0; i < elements.length; i++) {
        const isInViewport = getElementPosition(elements[i])
        if (isInViewport) {
          tabActive.value = elements[i].classList.value.replace(/.*cartTitle([0-9]+).*/g, '$1')
        }
        if (isInViewport) {
          break
        }
      }
    }

    onMounted(() => {
      window.addEventListener('scroll', handleScroll)
    })

    onUnmounted(() => {
      window.removeEventListener('scroll', handleScroll)
    })

    const loading = ref(true)
    const route = useRoute()
    const router = useRouter()

    // 详情数据
    const detailData: any = ref({})
    const initDetail = () => {
      httpRequest
        .post({
          url: 'caseLibraryDetail',
          params: { id: route.query.id }
        })
        .then((res) => {
          detailData.value = res.data
          currentID.value = res.data.id
          if(detailData.value.cpwsAlTitle){
            const title = document.getElementsByTagName('title')[0]
            title.innerHTML = detailData.value.cpwsAlTitle
          }
          loading.value = false
          nextTick(() => {
            handleScroll()
          })
          // 判断是否收藏
          httpRequest.post({
            url: 'judgeCollection3',
            params: { caseId: route.query.id }
          }).then(res2=>{
            isFavorValue.value = res2.data
          })
        })
        .catch((err) => {
          console.log(err)
        })
    }
    initDetail()

    // 点击关键词查询
    const doKeywordSearch = (keyword: any) => {
      const routeData = router.resolve({ path: '/CaseLibrary', query: { keyword: keyword } })
      window.open(routeData.href, '_blank')
    }

    // 点击关键词查询
    const doParamSearch = (paramName: any, code: any, name: any) => {
      const routeData = router.resolve({ path: '/CaseLibrary', query: { paramName, code, name } })
      window.open(routeData.href, '_blank')
    }

    // 纯净版标准版切换
    const pureState = reactive({
      id: '0',
      selectValue: '标准版',
      selectOptions: [
        { label: '标准版', id: '0' },
        { label: '纯净版', id: '1' },
        // { label: '标签版', id: '2' }
      ],
      change: (val: string) => {
        pureState.id = val
        const aa = fontSizeRef.value.querySelectorAll('.relate-link')
        if (pureState.id === '1' && aa) {
          aa.forEach((a: any) => {
            a.onclick = null
            a.classList.add('pure-text')
          })
        } else {
          jumpNewPage(fontSizeRef.value, '.relate-link', router)
        }
      }
    })
    // 字体大小切换
    const fontSizeRef = ref()
    const fontSizeState = reactive({
      curSize: 20, // 16-24
      sizeChange: debounce(500, (type: string) => {
        if (type === 'smaller' && fontSizeState.curSize < 18) {
          ElMessage({
            message: '已经缩小到最小',
            type: 'warning'
          })
          return
        }
        if (type === 'bigger' && fontSizeState.curSize > 22) {
          ElMessage({
            message: '已经放大到最大',
            type: 'warning'
          })
          return
        }
        fontSizeState.curSize =
          type === 'smaller'
            ? fontSizeState.curSize - 2
            : fontSizeState.curSize + 2
        fontSizeRef.value.style.fontSize = String(fontSizeState.curSize + 'px')
      }) as Function
    })

    // 背景颜色切换
    const skinState = reactive<IComObj>({
      data: theme,
      colorData: [],
      change: (item: IComObj) => {
        for (const [key, val] of Object.entries(item)) {
          document.documentElement.style.setProperty(
            '--' + `${key}`,
            String(val)
          )
        }
      },
      getData: () => {
        theme.forEach((i: IComObj) => {
          skinState.colorData.push(i.BackColor)
        })
      }
    })
    skinState.getData()

    //下载
    const dowmloadLink = ref('')
    // 收藏
    const isFavor = debounce(500, () => {
      isFavorValue.value = !isFavorValue.value
      isCollect(isFavorValue.value, currentID.value,3)
    })
    // 跳转法律图谱
    const toKnowledgeMap = () => {
      const { href } = router.resolve({
        name: 'KnowledgeMap',
        query: {
          id: currentID.value
        }
      })
      window.open(href, '_blank')
    }
    // 附件列表，下载附件
    const fileList = ref<any[]>([])
    // 左侧详情页
    const isFavorValue = ref(false)
    const detailPageList = ref<IComObj>({})
    const currentID = ref('')
    const currentName = ref('')
    // const quillRef = ref()

    // 是否为中央文件
    const isZywj = computed(() => {
      return detailPageList.value.properties?.find((item: any) => item.name == '效力级别')?.value == '中央文件'
    })

    // 文内检索
    const { startTag, endTag } = generateTagStyle({ tag: "p", style: { textIndent: "2em" }})
    const matchCount = ref(0)
    const textSearchData = computed(() => {
      const detail = JSON.parse(JSON.stringify(detailData.value))

      const contentKeys = ["cpwsAlCpyz", "cpwsAlGlsy", "cpwsAlJbaq", "cpwsAlCpjg", "cpwsAlCply"]
      contentKeys.forEach((key: string) => {
        if(!detail[key]) return;
        detail[key] = detail[key].split(/\r?\n/).map((item: string) => startTag + item + endTag).join("");
      })

      if (!searchValue.value || searchValue.value.length === 0) {
        return detail
      }
      if (detail) {
        matchCount.value = 0
        const searchText = searchValue.value.join(' ')
        if (detail.cpwsAlCpyz) {
          detail.cpwsAlCpyz = detail.cpwsAlCpyz.replaceAll(new RegExp('(' + searchText + ')(?!<)', 'g'), () => `<em class='search-em' id='#em${matchCount.value++}'>${searchText}</em>`)
        }
        if (detail.cpwsAlGlsy) {
          detail.cpwsAlGlsy = detail.cpwsAlGlsy.replaceAll(new RegExp('(' + searchText + ')(?!<)', 'g'), () => `<em class='search-em' id='#em${matchCount.value++}'>${searchText}</em>`)
        }
        if (detail.cpwsAlJbaq) {
          detail.cpwsAlJbaq = detail.cpwsAlJbaq.replaceAll(new RegExp('(' + searchText + ')(?!<)', 'g'), () => `<em class='search-em' id='#em${matchCount.value++}'>${searchText}</em>`)
        }
        if (detail.cpwsAlCpjg) {
          detail.cpwsAlCpjg = detail.cpwsAlCpjg.replaceAll(new RegExp('(' + searchText + ')(?!<)', 'g'), () => `<em class='search-em' id='#em${matchCount.value++}'>${searchText}</em>`)
        }
        if (detail.cpwsAlCply) {
          detail.cpwsAlCply = detail.cpwsAlCply.replaceAll(new RegExp('(' + searchText + ')(?!<)', 'g'), () => `<em class='search-em' id='#em${matchCount.value++}'>${searchText}</em>`)
        }
      }
      return detail
    })

    const matchKey = ref(0)
    const searchValue = ref<string[]>([])
    const matchIndex = ref(0)

    const changeSearch = (strArr: string[]) => {
      searchValue.value = strArr
      nextTick(() => {
        matchChange(0)
      })
    }

    const matchChange = (ind: number) => {
      matchIndex.value = ind

      let elements = document.querySelectorAll('.search-em')
      elements.forEach(element => {
        element.classList.remove('focus-em')
      })
      const element = <HTMLElement>document.querySelectorAll('.search-em')[ind]
      if (element) {
        element.classList.add('focus-em')
        element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })
      }
    }

    const doJumpAnchor = (index: string) => {
      let element = document.querySelector('.cartTitle' + index)
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })
      }
    }

    return {
      tabActive,
      doKeywordSearch,
      loading,
      matchCount,
      matchIndex,
      matchKey,
      matchChange,
      detailPageList,
      dowmloadLink,
      isFavor,
      isFavorValue,
      searchValue,
      changeSearch,
      fontSizeRef,
      fontSizeState,
      skinState,
      pureState,
      currentID,
      toKnowledgeMap,
      fileList,
      isZywj,
      detailData,
      textSearchData,
      doParamSearch,
      doJumpAnchor,
      matchLink,
      dynamicContent,
      lawSummary,
      popShow,
      popLeft,
      popTop,
      showPop,
      handleCopy,
      handleSearch,
      REQUIRE_LOGIN:process.env.VUE_APP_REQUIRE_LOGIN
    }
  }
})
